<template>
    <div class="alert-timeline__associate-info">
        <img class="alert-timeline__associate-image" :src="filesPath + associate.associateImage" width="80" height="80" v-if="associate.associateImage" />
        <img src="@/assets/images/user-profile.svg" class="alert-timeline__associate-image" width="80" height="80" alt="" v-else>
        
        <div class="alert-timeline__associate-name">{{ associate.associateName }}</div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { usePanicAlertStore } from '../../../stores/PanicAlertStore'

const { alert } = usePanicAlertStore()
const props = defineProps(['alertId'])
const filesPath = process.env.VUE_APP_FILES_PATH
let associate = ref({
    associateImage: '',
    associateName: '',
})

onMounted(() => {
    // * Find associate info
    findAssociateInfo()
})

function findAssociateInfo() {
    const associates = alert.associates
    const associateInfo = associates.find(associate => associate.alertId === parseInt(props.alertId))

    associate.value.associateImage = associateInfo.associateImage
    associate.value.associateName = associateInfo.associateName
}
</script>