export function alertAssociatesTrackingEvent() {
    // * Join to associates tracking event
    function joinAssociatesTrackingEvent(alertId) {
        console.log('Join in associates tracking channel ✅')

        return Echo.private(`associate-tracking.${alertId}`)
    }

    // * Leave to associates tracking event
    function leaveAssociatesTrackingEvent(alertId) {
        return Echo.leave(`associate-tracking.${alertId}`)
    }

    return { joinAssociatesTrackingEvent, leaveAssociatesTrackingEvent }
}