import { httpRequests } from "../../../helpers/HttpRequests"

export function associatesTrakerServices() {
    const { getRequest } = httpRequests()

    function getAssociatesLocationsService(alertId) {
        return getRequest(`/web/alerts_logs/associate_locations/${alertId}`)
    }    

    return { getAssociatesLocationsService }
}